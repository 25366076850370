/* eslint-disable import/named */
import * as React from "react"
import { graphql } from "gatsby"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"

import { Layout, Header, Footer, SEO, Info } from "../components"

type DataProps = {
  readonly data: {
    allContentfulAbout: {
      edges: Array<{
        node: { body: RenderRichTextData<ContentfulRichTextGatsbyReference> }
      }>
    }
  }
}

/**
 * @param data.allContentfulAbout    Rich text for about page.
 */
const About: React.FC<DataProps> = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="About"
        description="Learn about this project, its creators and why we made it."
      />
      <Header />
      <Info body={data.allContentfulAbout.edges[0].node.body} />
      <Footer />
    </Layout>
  )
}

export default About

export const query = graphql`
  query {
    allContentfulAbout(limit: 1) {
      edges {
        node {
          body {
            raw
          }
        }
      }
    }
  }
`
